<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                    @change="get_bancos(); get_cuentas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Punto de venta
                  <v-autocomplete
                    v-model="filtro.ptovta"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="all_locales.filter(l => l.empresa == filtro.empresa && l.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" xl="4" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha desde
                      <FechaPicker
                        v-model="filtro.fecha_desde"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col>
                      Fecha hasta
                      <FechaPicker
                        v-model="filtro.fecha_hasta"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Banco
                  <v-autocomplete
                    v-model="filtro.banco"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="bancos_filter.filter(b => b.cuentas > 0)"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="get_cuentas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipo de movimiento
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tipos_movimiento.filter(tm => tm.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Origen
                  <v-autocomplete
                    v-model="filtro.origen"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="origenes"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Cuenta
                  <v-autocomplete
                    v-model="filtro.cuenta"
                    item-text="numero"
                    item-value="codigo"
                    tabindex="1"
                    :items="cuentas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Código Gestor
                  <v-text-field
                    v-model.trim="filtro.gestor"
                    ref="gestorCod"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @blur="buscar_gestor()"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar gestor"
                        small
                        @click="dialog_gestor = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  <v-text-field
                    v-model.trim="nombre_gestor"
                    class="pt-6"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Forma de cobro
                  <v-autocomplete
                    v-model="filtro.forma_cobro"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="formas_cobro"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tarjeta
                  <v-autocomplete
                    v-model="filtro.tarjeta"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tarjetas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  <v-row>
                    <v-col class="pr-0">
                      Nº Movimiento
                      <v-text-field
                        v-model.trim="filtro.num_mov"
                        tabindex="1"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      Nº Comprobante
                      <v-text-field
                        v-model.trim="filtro.num_comp"
                        tabindex="1"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="3" md="2" xl="1" class="py-1">
                  Auditado
                  <v-autocomplete
                    v-model="filtro.auditado"
                    tabindex="1"
                    :items="opciones"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="estados_mov"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        v-model="selected"
        v-on:item-expanded="get_imputaciones"
        class="cebra elevation-2 mt-2"
        item-key="num_mov"
        sort-by="num_mov"
        :items="movimientos"
        :headers="headers"
        :expanded.sync="expanded"
        :search="search"
        :loading="load"
        :footer-props="{'items-per-page-options':[5, 10, 15],'show-current-page':true, 'show-first-last-page':true}"
        single-select
        single-expand
        sort-desc
        dense
        @click:row="rowClick"
      >
        <!-- filtro dentro del datatable -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- ultima row para el total -->
        <template slot="body.append">
          <tr class="font-weight-bold">
            <th style="font-size: 14px">Totales</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="font-size: 14px" class="text-right">
              {{ total_importe }}
            </th>
            <th style="font-size: 14px" class="text-right">
              {{ total_saldo }}
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </template>
        <!-- tabla de imputaciones -->
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <v-simple-table
              class="ma-4"
              style=""
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Código</th>
                    <th class="text-left">Ejecutivo</th>
                    <th class="text-right">Cobranza</th>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Estado</th>
                    <th class="text-right">Importe pagado</th>
                    <th class="text-right">Total cobranza</th>
                    <th class="text-right">Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in imputaciones"
                    :key="item.cobranza"
                  >
                    <td class="text-right">{{ item.ejecutivo_codigo }}</td>
                    <td>{{ item.ejecutivo_nombre }}</td>
                    <td class="text-right">{{ item.cobranza }}</td>
                    <td>{{ item.fecha }}</td>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.estado }}</td>
                    <td class="text-right">{{ formatMoney(item.importe) }}</td>
                    <td class="text-right">{{ formatMoney(item.total) }}</td>
                    <td class="text-right">{{ formatMoney(item.saldo) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <!-- columnas computadas -->
        <template v-slot:[`item.auditado`]="{ item }">
          <div class="d-flex justify-center">
            <v-checkbox
              v-model="item.auditado"
              :true-value="1"
              :false-value="0"
              hide-details
              dense
              @click.stop=""
            ></v-checkbox>
          </div>
        </template>
        <!-- nro de cuenta y banco -->
        <template v-slot:[`item.num_cuenta`]="{ item }">
          {{ item.tipo == 3 ? item.nro_cta_cheque : item.num_cuenta }}
          <v-tooltip color="secondary" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                style="margin-bottom: 2px"
                small
              >
                fas fa-university
              </v-icon>
            </template>
            <span>{{ item.tipo == 3 ? item.bco_pago_nom : item.banco_nombre }}</span>
          </v-tooltip>
        </template>
        <!-- <template v-slot:[`item.origen_nombre`]="{ item }">
          {{ item.origen_nombre }}
          <v-tooltip v-if="item.origen == 1" color="secondary" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                style="margin-bottom: 2px"
                small
              >
                fas fa-user
              </v-icon>
            </template>
            <span>{{ item.gestor_nombre }}</span>
          </v-tooltip>
        </template> -->
        <template v-slot:[`item.estado`]="{ item }">
          <v-icon
            class="ml-1"
            style="margin-bottom: 2px"
            :color="estados_mov.find(e => e.codigo == item.estado).color"
            :title="item.estado_nombre"
            small
          >
            {{ estados_mov.find(e => e.codigo == item.estado).icono }}
          </v-icon>
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="primary" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="info"
                class="mr-2"
                style="cursor: pointer"
                small
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <div>
              Tipo: <strong style="margin-left: 17px">{{ item.tipo_nombre }}</strong>
            </div>
            <div>
              Origen: <strong class="ml-1">{{ item.origen_nombre }} <span v-if="item.gestor_codigo">-</span> {{ item.gestor_codigo }}</strong>
            </div>
            <div v-if="item.gestor_nombre">
              Cliente: <strong class="ml-1">{{ item.gestor_nombre }}</strong>
            </div>
            <div v-if="item.tipo == 3">
              B. Emisor: <strong class="ml-2">{{ item.banco_nombre }}</strong>
            </div>
            <div v-if="item.tipo == 3 && item.banco_pago">
              B. Pagador: <strong>{{ item.banco_pago }}</strong>
            </div>
          </v-tooltip>
          <template v-if="item.modifica == 1">
            <v-icon
              v-if="p_editar == 1 && item.tipo != 3"
              title="Editar"
              color="indigo"
              class="mr-2"
              small
              @click.stop="editar(item)"
            >
              fas fa-pen
            </v-icon>
            <BtnConfirmar
              v-if="p_anular == 1 && item.estado != 2 && item.imputaciones == 0"
              icono="fas fa-ban"
              title="Anular"
              color="error"
              clase="mr-2"
              :texto="`¿Desea <strong>anular</strong> el movimiento Nº <strong>${item.num_mov}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="anular(item)"
            />
          </template>
          <v-icon
            v-if="item.transaccion_id != null && p_editar == 1"
            title="Ver caja"
            color="purple"
            class="mr-2"
            small
            @click.stop="ver_cc(item)"
          >
            fas fa-cash-register
          </v-icon>
          <v-icon
            v-if="item.origen == 1 && item.imputaciones > 0 && p_editar == 1"
            title="Ver imputaciones"
            color="orange"
            class="mr-2"
            small
            @click.stop="expand(item)"
          >
            fas fa-clipboard-list
          </v-icon>
          <v-icon
            v-if="item.tipo == 3"
            title="Ver cheque"
            color="green"
            small
            @click.stop="get_cheque(item)"
          >
            fas fa-money-check
          </v-icon>
          <!-- Para la redirección al extracto -->
          <v-tooltip bottom v-if="item.banco_extracto_id != 0 && p_extracto == 1">
            <template v-slot:activator="{ on }">
              <v-btn small icon :color="item.auditado == 1 ? 'success' : 'warning'" v-on="on" @click.stop="verExtracto(item)">
                <v-icon small>fas fa-exclamation</v-icon>
              </v-btn>
            </template>
            <span>Ver Extracto Bancario</span>
          </v-tooltip>
        </template>
        <!-- footer del comentario -->
        <template v-slot:footer>
          <v-row
            v-if="movimientos.length > 0"
            class="d-flex px-3 pb-3 "
            :class="selected.length > 0 ? 'pt-4' : 'pt-2'"
            no-gutters
          >
            <v-col cols="12" md="8">
              <v-expand-transition>
              <v-row v-if="selected.length > 0" no-gutters>
                <v-col cols="12" sm="10">
                  <v-textarea
                    v-model="selected[0].observacion"
                    label="Observación"
                    rows="2"
                    hide-details
                    outlined
                    :readonly="p_auditar != 1"
                    :filled="p_auditar != 1"
                    dense
                  ></v-textarea>
                </v-col>
                <!-- <v-col cols="12" sm="4" class="d-flex align-center justify-sm-start justify-end">
                  <BtnConfirmar
                    icono="fas fa-comment-medical"
                    nombre="Guardar observación"
                    :texto="`¿Desea guardar ésta observación para el movimiento Nº <strong>${selected[0].num_mov}?</strong>`"
                    clase="ml-3 mt-3 mt-sm-0"
                    @action="comentario(selected[0])"
                  />
                </v-col> -->
              </v-row>
              </v-expand-transition>
            </v-col>
            <v-col
              v-if="p_auditar == 1"
              cols="12" md="4"
              class="d-flex align-center justify-end"
            >
              <v-btn
                class="mt-3 mt-md-0"
                :title="movimientos.filter(m => m.auditado == 1).length == movimientos.length ? 'Desmarca los que originalmete estaban como no auditados' : 'Selecciona todos como auditados'"
                :color="movimientos.filter(m => m.auditado == 1).length == movimientos.length ? 'orange' : 'info'"
                :dark="movimientos.filter(m => m.auditado == 1).length == movimientos.length"
                small
                text
                @click="movimientos.filter(m => m.auditado == 1).length == movimientos.length ? quitar_seleccion() : seleccionar_todos()"
              >
                <v-icon
                  small
                  left
                >
                  fas fa-{{ movimientos.filter(m => m.auditado == 1).length == movimientos.length ? 'minus-square' : 'check-square' }}
                </v-icon>
                {{ movimientos.filter(m => m.auditado == 1).length == movimientos.length ? 'Quitar selección' : 'Seleccionar todos' }}
              </v-btn>
              <div>
                <BtnConfirmar
                  icono="fas fa-check-double"
                  nombre="Auditar"
                  color="pink accent-1"
                  clase="ml-2 mt-3 mt-md-0"
                  :dark="true"
                  @action="auditar()"
                />
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <ModalGestor
      v-model="dialog_gestor"
      @seleccion="seleccionar_gestor"
    />
    <ModificarMovBancario
      v-model="dialog_editar"
      :p_movimiento="movimiento"
      @editar="modificar_movimiento"
    />
    <Cheque
      v-model="dialog_cheque"
      :p_id="cheque_id"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { format_money, get_nombre_obj_arr, parseDate } from '../../util/utils'
import moment from 'moment'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import ModalGestor from '../../components/auditoria/ModalGestor'
import SearchDataTable from '../../components/util/SearchDataTable'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import ModificarMovBancario from '../../components/auditoria/ModificarMovBancario'
import Cheque from '../../components/auditoria/Cheque'

export default {
  data () {
    return {
      formatMoney: format_money,
      panel: 0,
      total_importe: 0,
      total_saldo: 0,
      p_auditar: 0,
      p_editar: 0,
      p_anular: 0,
      p_extracto: 0,
      load: false,
      cheque_id: null,
      dialog_cheque: false,
      dialog_editar: false,
      dialog_gestor: false,
      nombre_gestor: '',
      search: '',
      bancos_filter: [],
      imputaciones: [],
      movimientos: [],
      selected: [],
      expanded: [],
      cuentas: [],
      opciones: [
        { text: 'SI', value: 1 },
        { text: 'NO', value: 0 }
      ],
      headers: [
        { text: 'Audit.', value: 'auditado', align: 'center' },
        { text: 'Nº Mov.', value: 'num_mov', align: 'end' },
        { text: 'Nº Cuenta', value: 'num_cuenta', align: 'end' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Nº Comp.', value: 'num_comp', align: 'end', sortable: false },
        { text: 'Importe', value: 'importe', align: 'end',  formatter: format_money },
        { text: 'Saldo', value: 'saldo', align: 'end',  formatter: format_money },
        { text: 'Estado', value: 'estado', align: 'center' },
        { text: 'Forma cobro', value: 'forma_cobro_nombre' },
        { text: 'Tarjeta', value: 'tarjeta_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      movimientos_copy: [],
      movimiento: {},
      filtro: {
        empresa: null,
        sucursal: null,
        ptovta: null,
        banco: null,
        num_mov: null,
        num_comp: null,
        tipo: null,
        origen: null,
        cuenta: null,
        gestor: null,
        forma_cobro: null,
        tarjeta: null,
        auditado: null,
        estado: 1,
        fecha_desde: null,
        fecha_hasta: null
      }
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    this.$store.state.loading = true
    await this.$store.dispatch('get_all_locales')
    await this.$store.dispatch('bancos/get_tipos_movimiento')
    await this.$store.dispatch('cobros/get_formas_cobro_habilitadas')
    await this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
    await this.$store.dispatch('movBancarios/get_estados')
    await this.$store.dispatch('movBancarios/get_origenes')
    this.p_anular = await this.$store.dispatch('movBancarios/permiso_anular')
    this.p_auditar = await this.$store.dispatch('movBancarios/permiso_auditar')
    this.p_editar = await this.$store.dispatch('movBancarios/permiso_editar')
    let permisoPeticion = await this.$store.dispatch('movBancarios/permiso_extracto')
    if (permisoPeticion.exito == 0){
      this.$store.dispatch('show_snackbar', {
        text: permisoPeticion.msj,
        color: 'error',
      })
      return
    }
    this.p_extracto = permisoPeticion.permiso
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales', 'all_locales']),
    ...mapState('bancos', ['bancos', 'tipos_movimiento']),
    ...mapState('cobros', ['formas_cobro']),
    ...mapState('tarjetas', ['tarjetas']),
    ...mapState('movBancarios', ['estados_mov', 'origenes']),
  },
  components: {
    BtnFiltro,
    FechaPicker,
    ModalGestor,
    SearchDataTable,
    BtnConfirmar,
    ModificarMovBancario,
    Cheque
  },
  methods: {
    async get_cheque (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/get_cheque', item.num_mov)
        .then((res) => {
          this.cheque_id = res.data
          this.dialog_cheque = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async quitar_seleccion () {
      this.movimientos = await JSON.parse(JSON.stringify(this.movimientos_copy))
    },
    seleccionar_todos () {
      for (let movimiento of this.movimientos) {
        movimiento.auditado = 1
      }
    },
    async auditar () {
      // manda todo aquel q se modifico comentario o auditado
      let movimientos = []
      for (let index = 0; index < this.movimientos.length; index++) {
        const movimiento = this.movimientos[index]
        const movimiento_original = await this.movimientos_copy.find(m => m.num_mov == movimiento.num_mov)
        if (movimiento_original.auditado != movimiento.auditado || movimiento_original.observacion != movimiento.observacion) {
          movimientos.push({
            num_mov: movimiento.num_mov,
            auditado: movimiento.auditado,
            transaccion_id: movimiento.transaccion_id,
            fecha: movimiento.fecha,
            observacion: movimiento.observacion
          })
        }
      }
      if (movimientos.length == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'No se realizaon cambios en los movimientos',
          color: 'warning'
        })
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/auditar', { movimientos: movimientos })
        .then((res) => {
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
          this.movimientos_copy = JSON.parse(JSON.stringify(this.movimientos))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    /*async comentario (item) {
      let movimiento_original = await this.movimientos_copy.find(m => m.num_mov == item.num_mov)
      if ((movimiento_original.observacion == item.observacion) || (item.observacion == '' && movimiento_original.observacion == null)) {
        this.$store.dispatch('show_snackbar', {
          text: 'No se modificó la observación',
          color: 'warning'
        })
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/guardar_comentario', {
        num_mov: item.num_mov,
        observacion: item.observacion
      })
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          movimiento_original.observacion = JSON.parse(JSON.stringify(item.observacion))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },*/
    editar (item) {
      this.movimiento = JSON.parse(JSON.stringify(item))
      this.dialog_editar = true
    },
    modificar_movimiento (movimiento) {
      let mov = this.movimientos.find(m => m.num_mov == movimiento.num_mov)
      Object.assign(mov, movimiento)
    },
    async anular (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/anular', item.num_mov)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          item.saldo = 0
          item.estado = 2
          item.estado_nombre = get_nombre_obj_arr(this.estados_mov, 'codigo', 2)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async ver_cc (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/get_caja', {
        num_comp: item.transaccion_id,
        fecha: item.fecha
      })
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: `
            <strong>${res.data}</strong>.
            <v-hover v-slot="{ hover }">
              <a
                class="white--text"
                :class="hover ? '' : 'text-decoration-none'"
                href="control-cajas/${item.empresa_codigo},${item.sucursal_codigo},${item.transaccion_id},2"
                target="_blank"
              >
                Ver en Control Caja
              </a>
            </v-hover>
            `,
            color: 'purple lighten-1',
            timeout: 4000
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.empresa) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
        return
      }
      // si no selecciono nº de mov o comprobante debe seleccionar otros filtros
      if (!this.filtro.num_mov && !this.filtro.num_comp) {

        if (!this.filtro.sucursal && !this.filtro.origen && !this.filtro.fecha_desde) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar al menos un Origen, Sucursal o intervalo de fechas. <br/> O ingresar un Nº Movimiento / Comprobante',
            color: 'warning',
            timeout: 4000
          })
          return
        }
        else if (!this.filtro.sucursal && !this.filtro.origen) {
          let fecha = moment(new Date())
          if (this.filtro.fecha_hasta) fecha = parseDate(this.filtro.fecha_hasta)
          if (!moment(parseDate(this.filtro.fecha_desde)).isAfter(moment(fecha).subtract(2, 'months').subtract(1, 'day'))) {
            this.$store.dispatch('show_snackbar', {
              text: 'El intervalo de fechas no debe exceder 2 meses',
              color: 'warning'
            })
            return
          }
        }

      }
      this.load = true
      this.total_importe = 0
      this.total_saldo = 0
      this.movimientos = []
      this.movimientos_copy = []
      this.selected = []
      this.expanded = []
      this.search = ''
      await this.$store.dispatch('movBancarios/get_movimientos', this.filtro)
        .then((res) => {
          this.movimientos = res.data
          this.movimientos_copy = JSON.parse(JSON.stringify(this.movimientos))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
      this.calcular_totales()
    },
    async buscar_gestor () {
      const codigo = this.filtro.gestor
      if (codigo) {
        this.$store.state.loading = true
        await this.$store.dispatch('movBancarios/get_gestores', { codigo: codigo })
          .then((res) => {
            switch (res.size) {
              case 0:
                this.filtro.gestor = null
                this.nombre_gestor = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró ningún gestor con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
              case 1:
                this.nombre_gestor = res.data[0].nombre
                break
              default:
                this.filtro.gestor = null
                this.nombre_gestor = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontraron múltiples coincidencias para el gestor con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
            }
          })
          .catch(error => {
            this.filtro.gestor = null
            this.nombre_gestor = ''
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            this.$refs.gestorCod.focus()
          })
        this.$store.state.loading = false
      }
    },
    async get_cuentas () {
      this.filtro.cuenta = null
      this.cuentas = []
      const empresa = this.filtro.empresa
      const banco = this.filtro.banco
      if (empresa && banco) {
        this.$store.state.loading = true
        await this.$store.dispatch('bancos/get_cuentas', {
          inhabilitada: 0,
          empresa: empresa,
          banco: banco
        })
          .then((res) => {
            this.cuentas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async get_bancos () {
      this.filtro.banco = null
      this.bancos_filter = []
      if (this.filtro.empresa) {
        this.$store.state.loading = true
        await this.$store.dispatch('bancos/get_bancos_habilitados', this.filtro.empresa)
        this.bancos_filter = JSON.parse(JSON.stringify(this.bancos))
        this.$store.state.loading = false
      }
    },
    async get_imputaciones (payload) {
      if (payload.value) {
        const item = payload.item
        this.imputaciones = []
        this.$store.state.loading = true
        await this.$store.dispatch('movBancarios/get_imputaciones', item.num_mov)
          .then((res) => {
            this.imputaciones = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async imputaciones_controller (item) {
      await this.get_imputaciones({
        item: item,
        value: true
      })
      // si no tiene imputaciones muestra un modal y no expande la row
      if (this.imputaciones.length > 0) {
        this.expanded.push(item)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'El movimiento Nº ' + item.num_mov + ' no tiene imputaciones',
          color: 'warning'
        })
      }
    },
    async expand (item) {
      if (this.expanded.length == 0) {
        // si no hay ninguna row abierta, abre la que seleccionó
        this.imputaciones_controller(item)
      }
      /** si hay una row abierta, verifica si donde hizo click
       *  es la misma row que esta abierta, si es así la cierra.
       *  Caso contrario, elimina la row vieja y abre la nueva
       */
      else if (item == this.expanded[0]) {
        this.expanded = []
      }
      else {
        this.expanded = []
        this.imputaciones_controller(item)
      }
    },
    calcular_totales () {
      this.movimientos.forEach(mov => {
        this.total_importe += mov.importe
        this.total_saldo += mov.saldo
      })
      this.total_importe = format_money(this.total_importe)
      this.total_saldo = format_money(this.total_saldo)
    },
    seleccionar_gestor (gestor) {
      this.filtro.gestor = gestor.codigo
      this.nombre_gestor = gestor.nombre
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
    },
    limpiar () {
      this.nombre_gestor = ''
      this.filtro = {
        empresa: null,
        sucursal: null,
        ptovta: null,
        banco: null,
        num_mov: null,
        num_comp: null,
        tipo: null,
        origen: null,
        cuenta: null,
        gestor: null,
        forma_cobro: null,
        tarjeta: null,
        auditado: null,
        estado: 1,
        fecha_desde: null,
        fecha_hasta: null
      }
    },
    verExtracto(item){
      let path = '/conciliacion-bancaria/' + item.banco_extracto_id
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>