<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card
      flat
    >
      <v-card-title>
        <div v-if="params.nuevo == 1"><v-icon small class="ma-2">fas fa-plus-circle</v-icon> Nuevo Tipo de Comprobante</div>
        <div v-if="params.editar == 1"><v-icon small class="ma-2">fas fa-edit</v-icon> Editar Tipo de Comprobante</div>
        <div v-if="params.ver == 1"><v-icon small class="ma-2">fas fa-eye</v-icon> Ver Tipo de Comprobante</div>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <!-- Empresa, Tipo de Movimiento y Tipo de Caja -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedEmpresa"
              item-text="nombre_corto"
              :items="params.empresas"
              hide-details
              outlined
              dense
              return-object
              label="Empresa"
              clearable
              :disabled="params.ver == 1"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedTipoMov"
              item-text="tipo_nombre"
              :items="params.tiposMovim"
              hide-details
              outlined
              dense
              return-object
              label="Tipo Movimiento"
              :disabled="params.ver == 1"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            <v-autocomplete
              v-model="selectedTipoCaja"
              item-text="text"
              :items="params.tiposCajas"
              hide-details
              clearable
              outlined
              dense
              return-object
              label="Tipo Caja"
              :disabled="params.ver == 1"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Nombre y Abreviatura -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12" sm="6" md="6" class="py-1">
            <v-text-field
              v-model="nombreTipoComp"
              outlined
              dense
              type="text"
              hide-details
              label="Nombre"
              :readonly="params.ver == 1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1">
            <v-text-field
              v-model="abrevTipoComp"
              outlined
              dense
              type="text"
              hide-details
              label="Abreviatura"
              :readonly="params.ver == 1"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Modifica, Tipifica, Anula y es Gasto -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="modifica"
              :label="`${modificaNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="tipifica"
              :label="`${tipificaNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="anula"
              :label="`${anulaNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="gasto"
              :label="`${gastoNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- Estado y Digitaliza -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="estado"
              :label="`${estadoNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <v-checkbox
              v-model="digitaliza"
              :label="`${digitalizaNom}`"
              dense
              hide-details
              :disabled="params.ver == 1"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Botones -->
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar
            texto="¿Está seguro de dar de Alta al nuevo Tipo de Comprobante?"
            @action="nuevoTipoComprobante()"
            v-if="params.nuevo == 1"
          />
          <BtnConfirmar
            :texto="`¿Está seguro de modificar el Tipo de Comprobante ${nombreTipoComp}?`"
            @action="modificarTipoComp()"
            v-if="params.editar == 1"
          />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    BtnConfirmar,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalVerTipoComp', value)
      }
    },
    params: {
      get() {
        return this.datos
      }
    }
  },
  data() {
    return {
      selectedEmpresa: {},
      selectedTipoMov: {},
      selectedTipoCaja: {},
      nombreTipoComp: '',
      abrevTipoComp: '',
      modifica: false,
      modificaNom: 'No Modifica',
      tipifica: false,
      tipificaNom: 'No Tipifica',
      anula: false,
      anulaNom: 'No Anula',
      gasto: false,
      gastoNom: 'No es Gasto',
      estado: false,
      estadoNom: 'Inhabilitado',
      digitaliza: false,
      digitalizaNom: 'No Digitaliza'
    }
  },
  methods: {
    initForm(){
      if ((this.params.editar == 1 || this.params.ver == 1) && Object.keys(this.params.tipo_comprobante).length > 0){
        // completo los datos del tipo de comprobante, empresa
        let unaEmpresa = this.params.empresas.filter(element => element.id == this.params.tipo_comprobante.empresa_codigo)
        if (unaEmpresa.length > 0) this.selectedEmpresa = unaEmpresa[0]
        // tipo de movimiento
        let tipoMovim = this.params.tipo_comprobante.ingreso == 1 ? 0 : 1
        let unMovim = this.params.tiposMovim.filter(element => element.tipo_id == tipoMovim)
        if (unMovim.length > 0) this.selectedTipoMov = unMovim[0]
        // tipo de caja
        let unaCaja = this.params.tiposCajas.filter(element => element.value == this.params.tipo_comprobante.nivel_id)
        if (unaCaja.length > 0) this.selectedTipoCaja = unaCaja[0]
        // nombre
        this.nombreTipoComp = this.params.tipo_comprobante.nombre
        // abreviatura
        this.abrevTipoComp = this.params.tipo_comprobante.abreviatura
        // modifica
        this.modifica = this.params.tipo_comprobante.modifica == 1 ? true : false
        // tipifica
        this.tipifica = this.params.tipo_comprobante.tipifica == 1 ? true : false
        // anula
        this.anula = this.params.tipo_comprobante.anula == 1 ? true : false
        // gasto
        this.gasto = this.params.tipo_comprobante.gasto == 1 ? true : false
        // estado
        this.estado = this.params.tipo_comprobante.estado_codigo == 0 ? true : false
        // digitaliza
        this.digitaliza = this.params.tipo_comprobante.digitaliza == 1 ? true : false
      }else{
        if (this.params.nuevo != 1){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los datos del detalle seleccionado.',
            color: 'info',
          })
          this.activo = false
        }
      }
    },
    limpiarForm(){
      this.selectedEmpresa = {}
      this.selectedTipoMov = {}
      this.selectedTipoCaja = {}
      this.nombreTipoComp = ''
      this.abrevTipoComp = ''
      this.modifica = false
      this.tipifica = false
      this.anula = false
      this.gasto = false
      this.estado = false
      this.digitaliza = false
    },
    validarForm(){
      if (Object.keys(this.selectedTipoMov).length == 0) return {valido: false, msj: 'Debe elegir el Tipo de Movimiento.'}
      // comentado por mmacoritto 12-04-2023
      //if (Object.keys(this.selectedTipoCaja).length == 0) return {valido: false, msj: 'Debe elegir el Tipo de Caja.'}
      if (this.nombreTipoComp == null || this.nombreTipoComp == undefined || this.nombreTipoComp.toString().length == 0) return {valido: false, msj: 'Debe ingresar el Nombre.'}
      if (this.abrevTipoComp == null || this.abrevTipoComp == undefined || this.abrevTipoComp.toString().length == 0) return {valido: false, msj: 'Debe ingresar la Abreviatura.'}
      return {valido: true, msj: 'OK'}
    },
    async nuevoTipoComprobante(){
      let valido = this.validarForm()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // empresa
      let empresa= 0
      if (Object.keys(this.selectedEmpresa).length > 0) empresa = this.selectedEmpresa.id
      // armo el body
      let body = {
        empresa_codigo: empresa,
        tipo_movimiento: this.selectedTipoMov.tipo_id,
        tipo_caja: this.selectedTipoCaja ? this.selectedTipoCaja.value : null,
        nombre: this.nombreTipoComp,
        abreviatura: this.abrevTipoComp,
        modifica: this.modifica == false ? 0 : 1,
        tipifica: this.tipifica == false ? 0 : 1,
        anula: this.anula == false ? 0 : 1,
        gasto: this.gasto == false ? 0 : 1,
        estado: this.estado == false ? 0 : 1,
        digitaliza: this.digitaliza == false ? 0 : 1,
      }
      this.$store.state.loading = true
      let nuevoPeticion = await this.$store.dispatch('controlCaja/nuevo_tipo_comprobante', body)
      this.$store.state.loading = false
      if (nuevoPeticion.resultado == 1){
        this.$emit('refrescar')
        this.$store.dispatch('show_snackbar', {
          text: 'El nuevo Tipo de Comprobante fue dado de alta exitosamente.',
          color: 'success',
        })
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: nuevoPeticion.msj,
          color: 'error',
        })
      }
    },
    async modificarTipoComp(){
      let valido = this.validarForm()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      // empresa
      let empresa= 0
      if (Object.keys(this.selectedEmpresa).length > 0) empresa = this.selectedEmpresa.id
      // armo el body
      let body = {
        empresa_codigo: empresa,
        tipo_movimiento: this.selectedTipoMov.tipo_id,
        tipo_caja: this.selectedTipoCaja ? this.selectedTipoCaja.value : null,
        nombre: this.nombreTipoComp,
        abreviatura: this.abrevTipoComp,
        modifica: this.modifica == false ? 0 : 1,
        tipifica: this.tipifica == false ? 0 : 1,
        anula: this.anula == false ? 0 : 1,
        gasto: this.gasto == false ? 0 : 1,
        estado: this.estado == false ? 0 : 1,
        digitaliza: this.digitaliza == false ? 0 : 1,
        numero: this.params.tipo_comprobante.numero
      }
      this.$store.state.loading = true
      let updatePeticion = await this.$store.dispatch('controlCaja/actualizar_tipo_comp', body)
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$emit('refrescar')
        this.$store.dispatch('show_snackbar', {
          text: 'El Tipo de Comprobante fue actualizado exitosamente.',
          color: 'success',
        })
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    modifica: function(){
      if (this.modifica == false) this.modificaNom = 'No Modifica'
      else this.modificaNom = 'Modifica'
    },
    tipifica: function(){
      if (this.tipifica == false) this.tipificaNom = 'No Tipifica'
      else this.tipificaNom = 'Tipifica'
    },
    anula: function(){
      if (this.anula == false) this.anulaNom = 'No Anula'
      else this.anulaNom = 'Anula'
    },
    gasto: function(){
      if (this.gasto == false) this.gastoNom = 'No es Gasto'
      else this.gastoNom = 'Es Gasto'
    },
    estado: function(){
      if (this.estado == false) this.estadoNom = 'Inhabilitado'
      else this.estadoNom = 'Habilitado'
    },
    digitaliza: function(){
      if (this.digitaliza == false) this.digitalizaNom = 'No Digitaliza'
      else this.digitalizaNom = 'Digitaliza'
    },
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined) this.selectedEmpresa = {}
    },
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>