<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Tipo de Comprobante"
              v-if="tienePermiso == 1"
              @click="abrirModalTipoComp(1, null)"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Empresa, Tipo Movimiento, Tipo Caja, Estado y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Tipo Movimiento
                    <v-autocomplete
                      v-model="selectedTipoMov"
                      item-text="tipo_nombre"
                      :items="tiposMovim"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Tipo Caja
                    <v-autocomplete
                      v-model="selectedTipoCaja"
                      item-text="text"
                      :items="tiposCajas"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="d-flex justify-end align-end">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                      clase="mt-0"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="tiposComprobantes"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados.
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="abrirModalTipoComp(3, item)" v-on="on">
                  <v-icon small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="tienePermiso == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="abrirModalTipoComp(2, item)" v-on="on">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Tipos Comprobantes -->
    <ModalVerTipoComp 
      :datos="dialogVerTipoComp"
      @setearModalVerTipoComp="setModalVerTipoComp"
      @refrescar="buscar"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalVerTipoComp from '../../components/auditoria/ModalVerTipoComp.vue'
export default {
  data() {
    return {
      panel: 0,
      tienePermiso: 0,
      selectedEmpresa: {},
      empresas: [],
      selectedTipoMov: {},
      tiposMovim: [{tipo_id: 0, tipo_nombre: 'INGRESO'}, {tipo_id: 1, tipo_nombre: 'EGRESO'}],
      selectedTipoCaja: {},
      tiposCajas: [],
      selectedEstado: {},
      estados: [{estado_codigo: 0, estado_nombre: 'HABILITADO'}, {estado_codigo: 1, estado_nombre: 'INHABILITADO'}],
      load: false,
      headers: [
        { text: 'Nombre', align: 'left', value: 'nombre' },
        { text: 'Abreviatura', align: 'left', value: 'abreviatura' },
        { text: 'Anula', align: 'center', value: 'anula_nombre' },
        { text: 'Digitaliza', align: 'center', value: 'digitaliza_nombre' },
        { text: 'Gasto', align: 'center', value: 'gasto_nombre' },
        { text: 'Ingreso', align: 'center', value: 'ingreso_nombre' },
        { text: 'Modifica', align: 'center', value: 'modifica_nombre' },
        { text: 'Tipifica', align: 'center', value: 'tipifica_nombre' },
        { text: 'Estado', align: 'left', value: 'estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      tiposComprobantes: [],
      search: '',
      dialogVerTipoComp: {
        activo: false,
        ver: 0,
        editar: 0,
        nuevo: 0,
        tipo_comprobante: {},
        empresas: [],
        tiposMovim: [{tipo_id: 0, tipo_nombre: 'INGRESO'}, {tipo_id: 1, tipo_nombre: 'EGRESO'}],
        tiposCajas: []
      }
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalVerTipoComp
  },
  created(){
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.dialogVerTipoComp.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('controlCaja/init_form_tipos_comp')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.tiposCajas = initPeticion.tiposCajas
        this.tienePermiso = initPeticion.permiso
        this.dialogVerTipoComp.tiposCajas = initPeticion.tiposCajas
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      if (Object.keys(this.selectedEmpresa).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la Empresa para empezar la búsqueda.',
          color: 'warning',
        })
        return
      }
      this.tiposComprobantes = []
      // tipo de movimiento
      let tipoMov = -1
      if (Object.keys(this.selectedTipoMov).length > 0) tipoMov = this.selectedTipoMov.tipo_id
      // tipo de caja
      let tipoCaja = -1
      if (Object.keys(this.selectedTipoCaja).length > 0) tipoCaja = this.selectedTipoCaja.value
      // estado
      let estado = -1
      if (Object.keys(this.selectedEstado).length > 0) estado = this.selectedEstado.estado_codigo
      this.load = true
      let detallesPeticion = await this.$store.dispatch('controlCaja/get_tipos_comprobantes', {
        empresa_codigo: this.selectedEmpresa.id,
        tipo_movimiento: tipoMov,
        tipo_caja: tipoCaja,
        estado_codigo: estado
      })
      this.load = false
      if (detallesPeticion.resultado == 1){
        this.tiposComprobantes = detallesPeticion.tiposComp
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedTipoMov = {}
      this.selectedTipoCaja = {}
      this.selectedEstado = {}
    },
    setModalVerTipoComp(value){
      this.dialogVerTipoComp.activo = value
      if (this.dialogVerTipoComp.activo == false){
        this.dialogVerTipoComp.ver = 0
        this.dialogVerTipoComp.editar = 0
        this.dialogVerTipoComp.nuevo = 0
        this.dialogVerTipoComp.tipo_comprobante = {}
      }
    },
    abrirModalTipoComp(opcion, item){
      if (opcion == 1) this.dialogVerTipoComp.nuevo = 1
      if (opcion == 2) this.dialogVerTipoComp.editar = 1
      if (opcion == 3) this.dialogVerTipoComp.ver = 1
      if (opcion == 2 || opcion == 3) this.dialogVerTipoComp.tipo_comprobante = item
      this.dialogVerTipoComp.activo = true
    }
  },
}
</script>

<style>

</style>